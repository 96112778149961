<template>
    <form @submit.prevent="handleEdit"
          class="flex-1 overflow-y-auto h-full"
          v-if="allergen && form">

        <PageHead sticky
                  :title="`Allergen - ${allergen.name_de}`">
            <template #actions>
                <c-button class="mr-2" v-if="enclosing.prev"
                          :path="{ name: 'AllergenEdit', params: { id: enclosing.prev.id } }">&lt;</c-button>
                <c-button class="mr-2" disabled v-else>&lt;</c-button>
                <c-button class="mr-2" v-if="enclosing.next"
                          :path="{ name: 'AllergenEdit', params: { id: enclosing.next.id } }">&gt;</c-button>
                <c-button class="mr-2" disabled v-else>&gt;</c-button>

                <c-button :disabled="noChange">
                    Speichern
                    <component :is="saveIcon" class="h-5 w-5 rounded-full text-white ml-2"/>
                </c-button>
            </template>
        </PageHead>

        <nav class="relative z-0 flex divide-x divide-gray-200" aria-label="Tabs">
            <a v-for="(tab, tabIdx) in tabs"
               @click.prevent="currentTab = tabIdx"
               :key="tab.name"
               href="#"
               :class="[
                   currentTab === tabIdx ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                   'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
               ]">
                <span>{{ tab.name }}</span>
                <span aria-hidden="true"
                      :class="[currentTab === tabIdx ? 'bg-gray-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']"/>
            </a>
        </nav>
        <div class="p-6 bg-white">
            <div v-if="currentTab === 0">
                <div class="grid grid-cols-4 gap-6">
                    <div class="col-span-4">
                        <label for="name_de" class="block text-sm font-medium text-gray-700">Name</label>
                        <input type="text"
                               v-model="form.name_de"
                               name="name_de"
                               id="name_de"
                               autocomplete="cc-given-name"
                               class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"/>
                    </div>
                </div>
            </div>
            <div v-if="currentTab === 1">
                <div class="grid grid-cols-4 gap-6">
                    <div class="col-span-4">
                        <label for="name_en" class="block text-sm font-medium text-gray-700">
                            Name (EN)
                        </label>
                        <input type="text"
                               v-model="form.name_en"
                               name="name_en"
                               id="name_en"
                               autocomplete="cc-given-name"
                               class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"/>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import {diff} from 'deep-object-diff';
import {SaveIcon} from '@heroicons/vue/outline';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'AllergenEdit',
    data() {
        return {
            currentTab: 0,
            form: {
                name_de: '',
                name_en: '',
            },
            formOg: {},
            tabs: [
                {
                    name: 'Deutsch',
                    href: '#',
                },
                {
                    name: 'English',
                    href: '#',
                },
            ],
        };
    },
    computed: {
        ...mapGetters('products', ['getAllergenById', 'getEnclosingAllergens']),
        allergenId() {
            return this.$route.params?.id;
        },
        allergen() {
            return this.getAllergenById(this.allergenId);
        },
        noChange() {
            return Object.keys(diff(this.formOg, this.form)).length <= 0;
        },
        saveIcon() {
            return SaveIcon;
        },
        enclosing() {
            return this.getEnclosingAllergens(this.allergenId);
        },
    },
    beforeMount() {
        this.update();
    },
    watch: {
        $route() {
            this.update();
        },
        allergen(newAllergen, oldAllergen) {
            if (!oldAllergen) {
                this.form = JSON.parse(JSON.stringify(newAllergen));
            }
            if (newAllergen) {
                this.formOg = JSON.parse(JSON.stringify(newAllergen));
            }
        },
    },
    methods: {
        ...mapActions('products', ['editAllergen']),
        update() {
            if (this.allergen) {
                this.form = JSON.parse(JSON.stringify(this.allergen));
                this.formOg = JSON.parse(JSON.stringify(this.allergen));
            }
        },
        async handleEdit() {
            const editAllergen = await this.editAllergen({
                id: this.allergenId,
                allergen: this.form,
            });
            if (editAllergen.status === 200) {
                this.$router.push({name: 'Allergens'});
            }
        },
    },
};
</script>
